export const scrollBarStyle = {
  '&::-webkit-scrollbar': {
    width: '6px',
    height: '6px',
    borderRadius: '8px',
    backgroundColor: `rgba(0, 0, 0, 0.05)`,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: `rgba(0, 0, 0, 0.05)`,
  },
};
export const formatDate = (date, fallback = '') => {
  if (!date) return fallback;

  return new Date(date).toLocaleDateString('default', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};

export const trim = (str) => {
  return str?.length > 20 ? str.substring(0, 20) + '...' : str;
};

export const formatDateTime = (date, fallback = '') => {
  if (!date) return fallback;

  return new Date(date).toLocaleTimeString('default', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};

export const trimID = (str) => {
  return str?.length > 30 ? str.substring(0, 30) + '...' : str;
};

export function currencyFormat(num) {
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}
export const useLogOut = () => {
  return () => {
    sessionStorage.clear();
    setTimeout(() => {
      window.location.href = '/login';
    }, 500);
  };
};
