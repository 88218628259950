import axios from 'axios';
import * as API from '../url';
import axiosInstance from '../axiosInstance';

export const getUser = async () => {
  const res = await axiosInstance.get(API.GET_USER);
  return res.data;
};

export const getRefLink = async () => {
  const res = await axiosInstance.get(API.GET_REF_LINK);
  return res.data;
};
export const getPayments = async () => {
  const res = await axiosInstance.get(API.GET_PAYMENTS);
  return res.data;
};

export const sendRefInvite = async (body) => {
  const res = await axiosInstance.post(API.SEND_REF_INVITE, body);
  return res.data;
};

export const makePayment = async (body) => {
  const res = await axiosInstance.post(API.MAKE_PAYMENT, body);
  return res.data;
};

export const getActiveDeposit = async () => {
  const res = await axiosInstance.get(API.GET_ACTIVE_DEPOSIT);
  return res.data;
};
