import { createContext, useState, useContext, useEffect } from 'react';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [coinImg, setCoinImg] = useState('');
  const [coin, setCoin] = useState('');
  return (
    <AppContext.Provider
      value={{
        showSidebar,
        setShowSidebar,
        coinImg,
        setCoinImg,
        setCoin,
        coin,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export const useGlobalContext = () => {
  return useContext(AppContext);
};
