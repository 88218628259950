import { GiBilledCap } from 'react-icons/gi';
import { useNavigate } from 'react-router-dom';

const Logo = ({ color, bg, textColor }) => {
  const navigate = useNavigate();

  return (
    <div
      className="logo flex gap-3 items-center cursor-pointer"
      onClick={() => {
        navigate('/');
      }}
    >
      <div
        className={`${bg} flex place-items-center justify-center rounded-full  w-[40px] aspect-square`}
      >
        <GiBilledCap className={`${color} text-xl`} />
      </div>
      <p className={`${textColor} font-bold logo-text text-xl`}>Trading Cap</p>
    </div>
  );
};
export default Logo;
