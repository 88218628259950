import { useMutation, useQuery } from 'react-query';
import * as queryKey from '../queryKeys';
import {
  getActiveDeposit,
  getPayments,
  getRefLink,
  getUser,
  makePayment,
  sendRefInvite,
} from '../api/account';

export const useGetUser = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(queryKey.GET_USER, getUser, {
    ...options,
  });

  return { data, isLoading, refetch };
};

export const useGetRefLink = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    queryKey.GET_REF_LINK,
    getRefLink,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useSendRefInvite = (options = {}) => {
  const { mutate, isLoading } = useMutation(sendRefInvite, {
    mutationKey: queryKey.SEND_REF_INVITE,
    ...options,
  });

  return { mutate, isLoading };
};

export const useMakePayment = (options = {}) => {
  const { mutate, isLoading } = useMutation(makePayment, {
    mutationKey: queryKey.MAKE_PAYMENT,
    ...options,
  });

  return { mutate, isLoading };
};

export const useGetPayments = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    queryKey.GET_PAYMENTS,
    getPayments,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetActiveDeposit = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    queryKey.GET_ACTIVE_DEPOSIT,
    getActiveDeposit,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};
