export const PUBLIC_PATHS = {
  LOGIN: '/login',
  REGISTER: '/register',
  LANDING: '/landing',
  FORGOT_PASS: '/forgot-password',
  VERIFY_PASS_OTP: '/verify-password-otp',
  VERIFY_EMAIL: '/verify-email',
  RESET_PASS: '/reset-password',
  COMPANY: '/company',
  TERMS: '/terms',
  PRIVATE_POLICY: '/private-policy',
  CONTACT: '/contact',
  PAYMENT: '/payment',
};

export const PRIVATE_PATHS = {
  DASHBOARD: '/dashboard',
  ADD_FUND: '/deposits/add-funds',
  ADD_FUND_DETAILS: '/deposits/add-fund-details',
  PAYMENT_DETAILS: '/deposits/payment-details',
  WITHDRAW_FUND: '/withdrawals/withdraw-funds',
  WITHDRAW_REF: '/withdrawals/withdraw-ref',
  INVESTMENT_PLAN: '/investment-plan',
  PORTFOLIO: '/portfolio',
  ACTIVITY: '/activity',
  REWARDS: '/rewards',
  LOANS: '/loans',
  COIN_DETAILS: '/coin/:id',
};
