export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const COINS_BASE_URL = 'https://api.coingecko.com/api/v3/';
export const GET_TRENDING_COINS = `${COINS_BASE_URL}search/trending`;
export const GET_MARKET_COINS = `${COINS_BASE_URL}coins/markets?vs_currency=usd&order=market_cap_desc&per_page=50&page=1&sparkline=false&locale=en`;
export const GET_COIN_DETAILS = (id) =>
  `${COINS_BASE_URL}coins/${id}?localization=false&tickers=false&market_data=false&community_data=false&developer_data=true&sparkline=false`;
export const SIGN_UP = `${BASE_URL}api/auth/sign-up`;
export const LOGIN = `${BASE_URL}api/auth/sign-in`;
export const VERIFY_EMAIL = 'api/auth/verify-email-confirmation';
export const RESEND_VERIFY_EMAIL = 'api/auth/send-email-comfirmation';
export const SEND_PASS_OTP = 'api/auth/send-password-reset';
export const VERIFY_PASS_OTP = 'api/auth/verify-reset-password';
export const RESEND_PASS_OTP = 'api/auth/resend-password-reset';
export const CHANGE_PASS = 'api/auth/update-password';
export const GET_USER = 'api/user/get-user-profile';
export const GET_REF_LINK = 'api/user/get-referrer-link';
export const SEND_REF_INVITE = 'api/user/send-invite-link';
export const MAKE_PAYMENT = 'api/user/make-payment';
export const GET_PAYMENTS = 'api/user/get-payments';
export const GET_ACTIVE_DEPOSIT = 'api/user/get-active-deposit';
