import { FaBarsStaggered } from "react-icons/fa6";

import avater from "../../assets/avater.png";
import { useGlobalContext } from "../../../context/context";
import { Box, useMediaQuery, Flex, Text } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import Logo from "../../data/LandingPage/Logo";
import SideDrawer from "./SideDrawer";
import { useGetUser } from "../../../Services/query/account";

const Header = () => {
  const location = useLocation();
  const locationRoute = location.pathname;
  const [title, setTitle] = useState("");
  const [isMobile] = useMediaQuery("(max-width: 1226px)");
  const [open, setOpen] = useState(false);

  const { data } = useGetUser();

  useEffect(() => {
    switch (true) {
      case locationRoute.includes("dashboard"):
        return setTitle("Home");

      case locationRoute.includes("add-funds"):
        return setTitle("Add funds");

      case locationRoute.includes("withdraw-funds"):
        return setTitle("Withdraw funds");

      case locationRoute.includes("withdraw-ref"):
        return setTitle("Withdraw Ref Bonus");

      case locationRoute.includes("investment-plan"):
        return setTitle("Investment plans");

      case locationRoute.includes("portfolio"):
        return setTitle("Portfolio");

      case locationRoute.includes("activity"):
        return setTitle("Activity");

      case locationRoute.includes("rewards"):
        return setTitle("Rewards");

      case locationRoute.includes("loans"):
        return setTitle("Loans");
      default:
        return setTitle("");
    }
  }, [locationRoute]);
  const handleClick = () => {
    if (isMobile) {
      setOpen(true);
    }
  };
  return (
    <Flex
      className="fixed z-50 w-full py-[30px] justify-center text-white"
      style={{
        background: "#021603",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 0px 0px",
      }}
    >
      <Flex
        flexDir={isMobile ? "row-reverse" : "row"}
        className="flex items-center justify-between w-full px-8 cursor-pointer"
      >
        <Box>
          <Logo
            color="text-green-950"
            bg="bg-yellow-400"
            textColor="text-white"
          />
        </Box>

        {isMobile ? (
          ""
        ) : (
          <div>
            <p className="uppercase">{title}</p>
          </div>
        )}

        <div className="flex gap-2">
          {isMobile ? (
            ""
          ) : (
            <div className="w-[26px]">
              <img src={avater} alt="img avater" />
            </div>
          )}

          <Box display={isMobile ? "none" : ""}>
            <Text>{data?.data?.email}</Text>
          </Box>
          {isMobile ? (
            <FaBarsStaggered
              className="text-2xl"
              onClick={handleClick}
              display="none"
            />
          ) : (
            ""
          )}
        </div>
      </Flex>
      {isMobile ? (
        <SideDrawer
          isOpen={open}
          onClose={() => {
            setOpen(false);
          }}
        />
      ) : (
        ""
      )}
    </Flex>
  );
};
export default Header;
