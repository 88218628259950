import React, { useEffect, useState } from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Box,
  Flex,
  Text,
  CloseButton,
  Spinner,
  VStack,
  Collapse,
} from '@chakra-ui/react';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowDown, IoIosArrowUp, IoMdLogOut } from 'react-icons/io';
import { sideBarData as siderBarData } from '../../common/constant';
import { useLogOut } from '../../../utils/helper';
import { GiBilledCap } from 'react-icons/gi';
const SideDrawer = ({ isOpen, onClose }) => {
  const logout = useLogOut();

  const navigate = useNavigate();
  const [openSubItems, setOpenSubItems] = useState({});
  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState(false);

  const action = () => {
    setIsLoading(true);
    setTimeout(() => {
      logout();
      setIsLoading(false);
    }, 1000);
  };

  const handleToggleSubItem = (name) => {
    setOpenSubItems((prevState) => {
      const newOpenSubItems = {};

      Object.keys(prevState).forEach((item) => {
        newOpenSubItems[item] = false;
      });

      const activeParentItem = siderBarData.find((item) =>
        pathname.includes(item.path)
      )?.name;

      newOpenSubItems[activeParentItem] = true;

      if (name) newOpenSubItems[name] = !prevState[name];

      return newOpenSubItems;
    });
  };
  useEffect(() => {
    handleToggleSubItem(null);
  }, [pathname]);
  return (
    <Drawer
      autoFocus={false}
      size="md"
      isOpen={isOpen}
      placement="left"
      onClose={onClose}
    >
      {' '}
      <DrawerOverlay />
      <DrawerContent py="20px" color="#000" bg="rgb(2, 22, 3)">
        <DrawerBody p={0} overflowY="scroll">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            pt="1rem"
            px="2rem"
            borderBottom="1px solid #a0a0a0"
            pb="2rem"
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              w="26px"
              h="26px"
              color="#333"
              rounded="full"
              border="2px solid #B7B9BF"
              onClick={() => {
                onClose();
              }}
            >
              <CloseButton color="#ffff" fontSize="0.6rem" />
            </Flex>

            <div className="logo flex gap-3 items-center">
              <div
                className={`bg-yellow-400 flex place-items-center justify-center rounded-full  w-[30px] aspect-square`}
              >
                <GiBilledCap className={`text-green-950 text-xl`} />
              </div>
              <p className={`text-white font-semibold  logo-text `}>
                Trading Cap
              </p>
            </div>
          </Flex>
          <Box mt="20px" px="2rem">
            {siderBarData?.map((item, i) => {
              return (
                <VStack
                  key={i}
                  align="stretch"
                  mb="20px"
                  className={!pathname.includes(item?.path) && 'parent_nav'}
                  gap={0}
                >
                  <Flex
                    align="center"
                    p={2}
                    pt={3}
                    cursor="pointer"
                    onClick={() =>
                      item.sub
                        ? navigate(item.sub[0].path)
                        : navigate(item.path)
                        ? ''
                        : onClose()
                    }
                    bg="transparent"
                    color={
                      pathname.includes(item.path) || openSubItems[item.name]
                        ? '#fff'
                        : '#f2ea19'
                    }
                    _hover={{
                      bg: '#f0f4f8',
                      color: '#021603',
                    }}
                    h="45px"
                    borderRadius="10px"
                    transition=".3s ease-in-out"
                    position="relative"
                    gap="24px"
                  >
                    <Box className="initial_image">
                      {/* {pathname.includes(item.path)
                
                    ? item.icon } */}
                      {item.icon}
                    </Box>
                    <Box>
                      <Text fontSize="1rem">{item.name}</Text>
                    </Box>

                    {item.sub && (
                      <Flex
                        justifyContent="flex-end"
                        w="full"
                        // color={openSubItems[item.name] ? '#fff' : '#B4B4B4'}
                      >
                        {openSubItems[item.name] ? (
                          <IoIosArrowUp />
                        ) : (
                          <IoIosArrowDown />
                        )}
                      </Flex>
                    )}
                  </Flex>

                  {item.sub && (
                    <Collapse in={openSubItems[item.name]}>
                      <VStack
                        pl={3}
                        align="stretch"
                        borderBottomRadius={4}
                        pb="2"
                        gap={3}
                        pt={4}
                        onClick={onClose}
                      >
                        {item.sub.map((subItem) => (
                          <Link
                            key={subItem.name}
                            to={subItem.path}
                            style={{
                              padding: '4px 0',
                              paddingLeft: '30px',
                              fontSize: '12px',
                              textDecoration: 'none',
                              color: pathname.includes(subItem.path)
                                ? '#f2ea19'
                                : '#fff',
                              fontWeight: pathname.includes(subItem.path)
                                ? '700'
                                : '400',
                            }}
                          >
                            {subItem.name}
                          </Link>
                        ))}
                      </VStack>
                    </Collapse>
                  )}
                </VStack>
              );
            })}
          </Box>
          <Box
            mt="30px"
            borderTop="1px solid #575757"
            mx="20px"
            fontSize="14px"
            align="center"
            p={2}
            py="30px"
            fontWeight={400}
            cursor="pointer"
            onClick={action}
          >
            {isLoading ? (
              <Flex gap="8px" color="red" align="center">
                <Spinner size="sm" /> Logging Out
              </Flex>
            ) : (
              <Flex gap="8px" align="center" color="#fff" px={2}>
                <IoMdLogOut color="f2ea19" />
                Log Out
              </Flex>
            )}
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
export default SideDrawer;
