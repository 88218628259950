import {
  Box,
  Flex,
  Text,
  VStack,
  Collapse,
  Image,
  useMediaQuery,
} from '@chakra-ui/react';

import { useEffect, useState } from 'react';
import { useLogOut } from '../../../utils/helper';
import { sideBarData as siderBarData } from '../../common/constant';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useGlobalContext } from '../../../context/context';
import { Spinner } from '@chakra-ui/react';
import { IoMdLogOut } from 'react-icons/io';
import Logo from '../../data/LandingPage/Logo';

const Sidebar = () => {
  const logout = useLogOut();
  const [isMobile] = useMediaQuery('(max-width: 1226px)');

  const [isLoading, setIsLoading] = useState(false);
  const { showSidebar } = useGlobalContext();
  const action = () => {
    setIsLoading(true);
    setTimeout(() => {
      logout();
      setIsLoading(false);
    }, 1000);
  };
  const navigate = useNavigate();
  const [openSubItems, setOpenSubItems] = useState({});
  const { pathname } = useLocation();

  const handleToggleSubItem = (name) => {
    setOpenSubItems((prevState) => {
      const newOpenSubItems = {};

      Object.keys(prevState).forEach((item) => {
        newOpenSubItems[item] = false;
      });

      const activeParentItem = siderBarData.find((item) =>
        pathname.includes(item.path)
      )?.name;

      newOpenSubItems[activeParentItem] = true;

      if (name) newOpenSubItems[name] = !prevState[name];

      return newOpenSubItems;
    });
  };
  useEffect(() => {
    handleToggleSubItem(null);
  }, [pathname]);

  return (
    <Box
      display={isMobile ? 'none' : ''}
      h="full"
      position="fixed"
      zIndex={5}
      w="300px"
      bgColor="#021603"
      boxShadow="rgba(0, 0, 0, 0.1) 1px 0px 0px 0px"
      ml={showSidebar ? '-300px' : ''}
      transition="0.3s ease-in-out"
    >
      <Box h="100vh" overflowY="scroll">
        <Box h="5rem" px="2.5rem" pt="30px"></Box>
        <Box mx="20px" mt="20px">
          {siderBarData?.map((item, i) => {
            return (
              <VStack
                key={i}
                align="stretch"
                mb="20px"
                className={!pathname.includes(item?.path) && 'parent_nav'}
                gap={0}
              >
                <Flex
                  align="center"
                  p={2}
                  pt={3}
                  cursor="pointer"
                  onClick={() =>
                    item.sub ? navigate(item.sub[0].path) : navigate(item.path)
                  }
                  bg="transparent"
                  color={
                    pathname.includes(item.path) || openSubItems[item.name]
                      ? '#fff'
                      : '#f2ea19'
                  }
                  _hover={{
                    bg: '#f0f4f8',
                    color: '#021603',
                  }}
                  h="45px"
                  borderRadius="10px"
                  transition=".3s ease-in-out"
                  position="relative"
                  gap="24px"
                >
                  <Box className="hovered_image">{item.hover}</Box>

                  <Box className="initial_image">
                    {/* {pathname.includes(item.path)
                   
                    ? item.icon } */}
                    {item.icon}
                  </Box>
                  <Box>
                    <Text fontSize="1rem">{item.name}</Text>
                  </Box>

                  {item.sub && (
                    <Flex
                      justifyContent="flex-end"
                      w="full"
                      // color={openSubItems[item.name] ? '#fff' : '#B4B4B4'}
                    >
                      {openSubItems[item.name] ? (
                        <IoIosArrowUp />
                      ) : (
                        <IoIosArrowDown />
                      )}
                    </Flex>
                  )}
                </Flex>

                {item.sub && (
                  <Collapse in={openSubItems[item.name]}>
                    <VStack
                      pl={3}
                      align="stretch"
                      borderBottomRadius={4}
                      pb="2"
                      gap={3}
                      pt={4}
                    >
                      {item.sub.map((subItem) => (
                        <Link
                          key={subItem.name}
                          to={subItem.path}
                          style={{
                            padding: '4px 0',
                            paddingLeft: '30px',
                            fontSize: '12px',
                            textDecoration: 'none',
                            color: pathname.includes(subItem.path)
                              ? '#f2ea19'
                              : '#fff',
                            fontWeight: pathname.includes(subItem.path)
                              ? '700'
                              : '400',
                          }}
                        >
                          {subItem.name}
                        </Link>
                      ))}
                    </VStack>
                  </Collapse>
                )}
              </VStack>
            );
          })}
        </Box>
        <Box
          mt="30px"
          borderTop="1px solid #575757"
          mx="20px"
          fontSize="14px"
          align="center"
          p={2}
          py="30px"
          fontWeight={400}
          cursor="pointer"
          onClick={action}
        >
          {isLoading ? (
            <Flex gap="8px" color="red" align="center">
              <Spinner size="sm" /> Logging Out
            </Flex>
          ) : (
            <Flex gap="8px" align="center" color="#fff" px={2}>
              <IoMdLogOut color="f2ea19" />
              Log Out
            </Flex>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default Sidebar;
