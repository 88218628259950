import { useLocation, useNavigate } from 'react-router-dom';
import { Box, useMediaQuery } from '@chakra-ui/react';
import Sidebar from './AuthLayout/Sidebar';
import Header from './AuthLayout/Header';

export const AuthLayout = ({ children }) => {
  const [isMobile] = useMediaQuery('(max-width: 1226px)');
  return (
    <Box overflowX={isMobile ? 'hidden' : ''} bg="#f0f4f8" position="relative">
      <Box minH="100vh" overflowX="hidden">
        <Header />
        {!isMobile && (
          <Box>
            <Sidebar />
          </Box>
        )}

        <Box
          w="100%"
          pt={isMobile ? '120px' : '125px'}
          minH="100vh"
          pl={!isMobile ? '340px' : '20px'}
          pr={!isMobile ? '45px' : '20px'}
          pb={10}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export const NonAuthLayout = ({ children }) => {
  return <div>{children}</div>;
};
