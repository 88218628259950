import { TfiDashboard } from "react-icons/tfi";
import { TbMoneybag } from "react-icons/tb";
import { RiMoneyDollarBoxLine } from "react-icons/ri";
import { SiWebmoney } from "react-icons/si";

import { GiNetworkBars } from "react-icons/gi";
import { SiSololearn } from "react-icons/si";
import { RiSecurePaymentFill } from "react-icons/ri";
import { MdInvertColorsOff } from "react-icons/md";
import { FaToiletsPortable } from "react-icons/fa6";
import { FaMegaport } from "react-icons/fa6";
import bnb from '../assets/bnb.png';
import ada from '../assets/ada.png';
import avax from '../assets/avax.png';
import bitcoin from '../assets/bitcoin.png';
import bnb2 from '../assets/bnb2.png';
import dodgeCoin from '../assets/dodgecoin.png';
import eth from '../assets/etherium.png';
import t from '../assets/t.png';
import { RiCoinsFill } from 'react-icons/ri';
import { GiTrade } from 'react-icons/gi';
import { BiMoneyWithdraw } from 'react-icons/bi';
import { MdCastForEducation } from 'react-icons/md';
import { SiMoneygram } from 'react-icons/si';
import heroImg from '../assets/imgione.png';
import heroImgTwo from '../assets/hero-img-2.webp';
import heroImgThree from '../assets/hero-img-4.png';

import reviewImgOne from "../assets/Ellipse 128.svg";
import reviewImgTwo from "../assets/Ellipse 129.svg";
import reviewImgThree from "../assets/Ellipse 130.svg";

export const sideBarData = [
  {
    id: 1,
    name: "Dashboard",
    path: "/dashboard",
    icon: <TfiDashboard />,
  },
  {
    id: 2,
    name: "Deposit",
    path: "/deposits",
    icon: <TbMoneybag />,
    sub: [
      {
        id: 1,
        name: "Add Funds",
        path: "/deposits/add-funds",
      },
    ],
  },
  {
    id: 3,
    name: "Withdrawals",
    path: "/withdrawals",
    icon: <TbMoneybag />,
    sub: [
      {
        id: 1,
        name: "Withdraw Funds",
        path: "/withdrawals/withdraw-funds",
      },
      {
        id: 2,
        name: "Withdraw Ref Bonus",
        path: "/withdrawals/withdraw-ref",
      },
    ],
  },
  {
    id: 4,
    name: "Investment Plans",
    path: "/investment-plan",
    icon: <RiMoneyDollarBoxLine />,
  },
  {
    id: 5,
    name: "Portfolio",
    path: "/portfolio",
    icon: <SiWebmoney />,
  },
  {
    id: 6,
    name: "Activity",
    path: "/activity",
    icon: <TfiDashboard />,
  },
  {
    id: 7,
    name: "Rewards",
    path: "/rewards",
    icon: <RiCoinsFill />,
  },
  {
    id: 8,
    name: "Loans",
    path: "/loans",
    icon: <SiWebmoney />,
  },
];

export const cardData = [
  {
    id: 1,
    name: "Total Balance",
    usdBalance: 0.0,
    btcBalance: 0.0,
    icon: <GiNetworkBars />,
  },
  {
    id: 2,
    name: "Daily Earnings",
    usdBalance: 0.0,
    btcBalance: 0.0,
    icon: <SiSololearn />,
  },
  {
    id: 3,
    name: "Processed Payment",
    usdBalance: 0.0,
    btcBalance: 0.0,
    icon: <RiSecurePaymentFill />,
  },
  {
    id: 4,
    name: "Investment Rate",
    usdBalance: 0,
    icon: <MdInvertColorsOff />,
  },
  {
    id: 5,
    name: "PORTFOLIO (FIAT)",
    usdBalance: 0.0,
    btcBalance: 0.0,
    icon: <FaToiletsPortable />,
  },
  {
    id: 6,
    name: "PORTFOLIO (BTC)",
    usdBalance: 0.0,
    btcBalance: 0.0,
    icon: <FaMegaport />,
  },
];

export const slides = [
  {
    title: "Request POS",
    img: heroImg,
    text: "Get a POS machine to manage all your business accounts and manage transactions",
  },
  {
    title: "Invest",
    img: heroImg,
    text: "Your Path to Financial Freedom Begins Here",
  },
  {
    title: "Request Card",
    img: heroImg,
    text: "Get  cards for your business and perform physical, online transactions with ease.",
  },
  {
    title: "Savings",
    img: heroImg,
    text: "Start Saving and Achieving Your Financial Goals.",
  },
];

export const plans = [
  {
    id: 1,
    title: "Prime Plan",
    min: "Minimum - $ 100",
    max: "Maximum deposit - $ 1,999",
    Profit: "Profits - 20%",
    duration: "Duration 2 days",
    ref: "Referral earning 10%",
    withdraw: "Manual/Auto Withdrawal",
  },
  {
    id: 2,
    title: 'Platoon Plan',
    min: 'Minimum - $ 2,000',
    max: 'Maximum deposit - $ 9,900',
    Profit: 'Profits - 60% Daily',
    duration: 'Duration 5 days',
    ref: 'Referral earning 20%',
    withdraw: 'Manual/Auto Withdrawal',
  },
  {
    id: 3,
    title: 'Advanced Plan',
    min: 'Minimum - $ 10,000',
    max: 'Maximum deposit - $ 19,900',
    Profit: 'Profits - 100% Daily',
    duration: 'Duration 7 days',
    ref: 'Referral earning 30%',
    withdraw: 'Manual/Auto Withdrawal',
    days: '7 Days Withdraw capital',
  },
  {
    id: 4,
    title: 'Expert Plan',
    min: 'Minimum - $ 20,000',
    max: 'Maximum deposit - Unlimited',
    Profit: 'Profits - 200% daily',
    duration: 'Duration 1 Month',
    ref: 'Referral earning 50%',
    withdraw: 'Manual/Auto Withdrawal',
    days: '1 Month Withdraw capital',
  },
];
export const stepsToMakeDeposit = [
  "Click on the payment option you wish to send funds to the wallet address assigned to your account.",
  "Enter the amount you wish to deposit and click deposit.",
  "At the next page, a wallet address will be displayed to you to transfer the funds. Copy the wallet address shown to you and transfer the funds to the wallet address.",
  "Once the funds has been transfered, click on upload payment proof and upload your payment proof.",
];

export const coins = [
  {
    id: "1",
    name: "BITCOIN",
    img: bnb,
    address: "bc1q09crp38s0g9hyvn5g4dhu7l0a8xt3tcf08qjg2",
  },

  {
    id: "2",
    name: "USDT (TRC20)",
    img: t,
    address: "TW8QLKn4fpFEL63uBwJpUW3pM23Y6oqGre",
  },
  {
    id: "3",
    name: "ETH (ERC20)",
    img: eth,
    address: "0x51abd26953bc46b7fe6cce43899667e6122bded6",
  },
];

export const benefit = [
  {
    title: "Flexible Trading",
    details:
      "The latest trends in options trading: Digital Option, Pending Orders, Bets Copying. Payouts up to 128%",
    icon: <GiTrade />,
  },
  {
    title: "Easy Deposits and Withdrawals",
    details:
      "Withdraw and deposit your money in an instant using a wide range of available payment systems.",
    icon: <BiMoneyWithdraw />,
  },
  {
    title: "Diverse Trading Instruments",
    details:
      "Multiple assets for any trader: currency, commodities, stocks, cryptocurrencies",
    icon: <SiMoneygram />,
  },
  {
    title: "Comprehensive Education",
    details:
      "Our Help section contains tutorials, guides as well as trading strategies",
    icon: <MdCastForEducation />,
  },
];

export const test = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
];

export const trendingCoins = [
  "Score",
  "Symbol",
  "Name",
  "Price",
  "Mkt Cap",
  "CHG",
  "Price_btc",
  "",
];

export const aboutUs = [
  {
    title: "Company",
    link: "/company",
  },
  {
    title: "Contacts",
    link: "/contact",
  },

  {
    title: "Terms and Conditions",
    link: "/terms",
  },
  {
    title: "Private Policy",
    link: "/private-policy",
  },
];

export const footerLinks = [
  {
    title: "Terms and Conditions",
    link: "/terms",
  },
  {
    title: "Contacts",
    link: "/contact",
  },
  {
    title: "Join Us",
    link: "/login",
  },
];

export const footerLinks2 = [
  {
    title: "Privacy Policy",
    link: "/private-policy",
  },
  {
    title: "Payment Policy",
    link: "",
  },
];
export const marketCoinsHeader = [
  "img",
  "Price",
  "Price change %",
  "Market cap",
  "",
];
export const invite = [
  {
    title: "ADVANCED AFFILIATE STATISTICS",
    text: "You get different tools for checking the efficiency of your affiliate campaigns",
  },
  {
    title: "DIFFERENT TYPES OF PROMO MATERIALS",
    text: "You can use landing pages, videos, banners for promoting your affiliate campaigns on the internet",
  },
  {
    title:
      "ADVANCED YOUR COMMISSION FROM EACH REFERRAL`S TRADING ORDERCED AFFILIATE STATISTICS",
    text: "The more your referrals trade, the bigger your commission is. You will get your commission regardless of your referrals trading result",
  },
  {
    title: "REGULAR PAYMENTS",
    text: "A lot of payment methods are available to withdraw your profits",
  },
];
export const trades = [
  {
    title: "600.xhkg 298.3",
    text: "-1.44%",
    success: false,
  },
  {
    title: "1398.xhkg 3.7",
    text: "0%",
    success: false,
  },
  {
    title: "857.xhkg 5.35",
    text: "+0.64%",
    success: true,
  },
  {
    title: "989.xhkg 4.55",
    text: "0%",
    success: false,
  },
  {
    title: "MSFT 370.7",
    text: "+0.21%",
    success: true,
  },
  {
    title: "2319.xhkg 33.9",
    text: " -0.81%",
    success: false,
  },
];
export const heroImgs = [heroImg, heroImgTwo, heroImgThree];

export const activityLogsHeader = [
  "ID#",
  "Date",
  "Amount $",
  "Status",
  "Crypto",
];
export const reviewData = [
  {
    id: 1,
    review:
      "I have been using this investment app for the past six months, and I couldn't be happier. The user interface is intuitive and has helped my investment decision. Highly recommended for both beginners and experienced investors! ",
    name: "Brandy Jean",
    post: "Secretary",
    image: reviewImgOne,
  },
  {
    id: 2,
    review:
      "This investment app gets the job done, but there's room for improvement. The interface is clean, and it offers a decent range of investment options. The Customer support is quite responsive and the educational materials are helpful.",
    name: "Michael Royce",
    post: "Sales Manager",
    image: reviewImgTwo,
  },
  {
    id: 3,
    review:
      "I've been using this investment app for about six months, and my experience has been generally positive. The app provides a straightforward platform for managing investments, and the portfolio analysis tools are useful.",
    name: "John Roberts",
    post: "Chief Accountant",
    image: reviewImgThree,
  },
];
export const activityLogs = [];
export const funding = [];
