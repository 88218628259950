import { lazy } from 'react';
import WithSuspense from '../components/Loaders/WithSuspense';
import { PRIVATE_PATHS, PUBLIC_PATHS } from './constants';
import { Navigate } from 'react-router-dom';

const {
  DASHBOARD,
  ADD_FUND,
  WITHDRAW_FUND,
  WITHDRAW_REF,
  INVESTMENT_PLAN,
  PORTFOLIO,
  ACTIVITY,
  REWARDS,
  LOANS,
  ADD_FUND_DETAILS,
  PAYMENT_DETAILS,
  COIN_DETAILS,
} = PRIVATE_PATHS;
const {
  LOGIN,
  REGISTER,
  FORGOT_PASS,
  VERIFY_PASS_OTP,
  VERIFY_EMAIL,
  RESET_PASS,
  LANDING,
  COMPANY,
  TERMS,
  PRIVATE_POLICY,
  CONTACT,
  PAYMENT,
} = PUBLIC_PATHS;
const Login = WithSuspense(lazy(() => import('../pages/Authentication/Login')));

const Register = WithSuspense(
  lazy(() => import('../pages/Authentication/Register'))
);

const VerifyEmail = WithSuspense(
  lazy(() => import('../pages/Authentication/VerifyEmail'))
);

const Payment = WithSuspense(lazy(() => import('../pages/Payment/Payment')));
const Landing = WithSuspense(
  lazy(() => import('../pages/Authentication/Landing'))
);
const Dashboard = WithSuspense(
  lazy(() => import('../pages/Dashboard/Dashboard'))
);
const ResetPassword = WithSuspense(
  lazy(() => import('../pages/Authentication/ResetPassword'))
);
const ForgotPassword = WithSuspense(
  lazy(() => import('../pages/Authentication/ForgetPassword'))
);
const VerifyCodePass = WithSuspense(
  lazy(() => import('../pages/Authentication/VerifyCodePass'))
);
const WithdrawBonus = WithSuspense(
  lazy(() => import('../pages/Dashboard/Dashboard'))
);
const WithdrawFunds = WithSuspense(
  lazy(() => import('../pages/Dashboard/Dashboard'))
);
const InvestmentPlans = WithSuspense(
  lazy(() => import('../pages/InvestmentPlans/InvestmentPlans'))
);

const Portfolio = WithSuspense(
  lazy(() => import('../pages/Portfolio/Portfolio'))
);
const AddFund = WithSuspense(lazy(() => import('../pages/AddFund/AddFund')));

const AddFundsDetails = WithSuspense(
  lazy(() => import('../pages/AddFund/AddFundsDetails'))
);
const PaymentDetails = WithSuspense(
  lazy(() => import('../pages/AddFund/PaymentDetails'))
);

const Activity = WithSuspense(lazy(() => import('../pages/Activity/Activity')));

const Rewards = WithSuspense(lazy(() => import('../pages/Rewards/Rewards')));

const Loans = WithSuspense(lazy(() => import('../pages/Loans/Loans')));

const Company = WithSuspense(
  lazy(() => import('../pages/Authentication/Company'))
);
const Terms = WithSuspense(
  lazy(() => import('../pages/Authentication/TermsAndConditions'))
);
const PrivatePolicy = WithSuspense(
  lazy(() => import('../pages/Authentication/PrivatePolicy'))
);
const Contact = WithSuspense(
  lazy(() => import('../pages/Authentication/Contact'))
);
const MarketDetails = WithSuspense(
  lazy(() => import('../pages/Dashboard/CoinsDetails'))
);

export const PRIVATE_ROUTES = [
  { path: DASHBOARD, element: <Dashboard /> },
  { path: ADD_FUND, element: <AddFund /> },
  { path: ADD_FUND_DETAILS, element: <AddFundsDetails /> },
  { path: PAYMENT_DETAILS, element: <PaymentDetails /> },
  { path: WITHDRAW_FUND, element: <WithdrawFunds /> },
  { path: WITHDRAW_REF, element: <WithdrawBonus /> },
  { path: INVESTMENT_PLAN, element: <InvestmentPlans /> },
  { path: PORTFOLIO, element: <Portfolio /> },
  { path: ACTIVITY, element: <Activity /> },
  { path: REWARDS, element: <Rewards /> },
  { path: LOANS, element: <Loans /> },
  { path: COIN_DETAILS, element: <MarketDetails /> },
  { path: '*', element: <Navigate to="/dashboard" replace /> },
];

export const PUBLIC_ROUTES = [
  { path: LOGIN, element: <Login /> },
  { path: LANDING, element: <Landing /> },
  { path: REGISTER, element: <Register /> },
  { path: VERIFY_EMAIL, element: <VerifyEmail /> },
  { path: RESET_PASS, element: <ResetPassword /> },
  { path: FORGOT_PASS, element: <ForgotPassword /> },
  { path: VERIFY_PASS_OTP, element: <VerifyCodePass /> },
  { path: COMPANY, element: <Company /> },
  { path: TERMS, element: <Terms /> },
  { path: PRIVATE_POLICY, element: <PrivatePolicy /> },
  { path: CONTACT, element: <Contact /> },

  { path: PAYMENT, element: <Payment /> },
  { path: '*', element: <Navigate to="/landing" replace /> },
];
